import axios from 'axios';

import pckgJson from '../package.json';

const ax = axios.create({
  baseURL: 'https://media.unidcinemidia.com.br',
});

interface MediaDataRawObj {
  id: number;
  sha1: string;
  video: boolean;
  approved: boolean;
  order?: number;
  length?: number;
}

export interface MediaData {
  id: number;
  sha1: string;
  isVideo: boolean;
  isApproved: boolean;
  orderId?: number;
  length?: number;
}

export interface BomboPrices {
  [key: string]: {
    price: number;
    text: string;
  };
}

export interface PlayerData {
  loopLen: number;
  quotaCount: number;
  quotaLen: number;

  content: MediaData[];
  ads: MediaData[];
  bomboPrices: BomboPrices[];
}

export async function getPlayerData(
  serial: string,
  electronVersion: string,
  previewMode: boolean,
): Promise<PlayerData | undefined> {
  try {
    const { data }: any = await ax.get(`/dynamic-playlist/${electronVersion}-${pckgJson.version}/${serial}`, {
      params: previewMode ? { preview: 1 } : undefined,
      timeout: 1000 * 10,
    });

    const mapMedia = (e: MediaDataRawObj): MediaData => {
      return {
        id: e.id,
        sha1: e.sha1,
        isVideo: e.video,
        isApproved: e.approved,
        orderId: e.order,
        length: e.length,
      };
    };

    const ret: PlayerData = {
      loopLen: data.loopLen || 0,
      quotaLen: data.quotaLen || 0,
      quotaCount: data.quotaCount || 0,
      content: (data.playlist || []).map(mapMedia),
      ads: (data.ads || []).map(mapMedia),
      bomboPrices: data.bomboPrices || [],
    };

    if (!ret.loopLen || !ret.quotaLen || !ret.quotaCount) return undefined;
    return ret;
  } catch (err) {
    console.error(err);
    return undefined;
  }
}

export async function getMedia(url: string): Promise<ArrayBuffer | undefined> {
  try {
    const { data } = await ax({
      url,
      method: 'get',
      responseType: 'arraybuffer',
      timeout: 1000 * 3600,
    });

    return data as ArrayBuffer;
  } catch (err) {
    console.error(err);
    return undefined;
  }
}
