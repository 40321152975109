import * as localforage from 'localforage';
import { action, makeObservable, observable } from 'mobx';

import pkg from '../../package.json';
import { getPlayerData, PlayerData } from '../api';
import Media from './Media';
import Store from './store';

type Status = 'unknown' | 'invalid' | 'valid';

export default class Player {
  status: Status = 'unknown';
  media?: Media;
  previewModeInteraction = false;

  contentIdx = -1;
  adIdx = -1;

  serial: string;
  previewMode: boolean;
  playerData?: PlayerData;
  electronVersion: string;

  content: Media[] = [];
  ads: Media[] = [];

  loopCounter = 0;
  loopAdInterval = 0;

  constructor(private store: Store) {
    makeObservable(this, {
      status: observable,
      media: observable,
      previewModeInteraction: observable,
      setPlayerStatus: action,
      setMedia: action,
      previewInteraction: action,
    });

    this.serial = window.location.pathname.replace('/', '');

    const params = new URLSearchParams(window.location.search);
    this.electronVersion = params.get('v') || 'dev';
    this.previewMode = params.get('preview') === '1';

    console.log(`UPlayer v${pkg.version} - ${this.electronVersion}`);
    document.title = `UNID Player - v${pkg.version} - ${this.electronVersion}`;

    if (this.previewMode) {
      // Reexibe o aviso de preview a cada 30 minutos
      setInterval(() => this.previewInteraction(false), 30 * 60 * 1000);
    }

    setTimeout(() => this.fetchPlayerData(), 5000);
    // this.fetchPlayerData();
    setInterval(() => this.fetchPlayerData(), 60000);
  }

  setPlayerStatus(status: Status): void {
    this.status = status;
  }

  setMedia(media?: Media): void {
    if (this.media === media || this.store.needsReaload) {
      window.location.reload();
    }

    this.media = media;
  }

  previewInteraction(status: boolean = true): void {
    this.previewModeInteraction = status;
  }

  isSyncing(): boolean {
    const medias = [...this.content, ...this.ads];

    for (const media of medias) {
      if (media.status === 'syncing') return true;
    }

    return false;
  }

  async fetchPlayerData(): Promise<void> {
    if (!this.serial) return this.setPlayerStatus('invalid');

    // Fetch player data
    this.playerData = await getPlayerData(this.serial, this.electronVersion, this.previewMode);
    let usingLocalCache = false;

    // Check if player data was returned
    if (!this.playerData) {
      usingLocalCache = true;
      console.warn('[SYNC] Failed to get player data.. trying local cache.');

      // Tries to get player data from cache
      try {
        this.playerData = JSON.parse((await localforage.getItem('player-data')) || '');
      } catch (err) {
        console.error(err);
        this.playerData = undefined;
      }

      if (!this.playerData) return this.setPlayerStatus('invalid');
    } else {
      this.removeUnusedMedias();
    }

    // Save player data
    try {
      localforage.setItem('player-data', JSON.stringify(this.playerData));
    } catch (err) {
      console.error(err);
    }

    const { loopLen, content, ads, bomboPrices } = this.playerData;

    this.content = content.map((mediaData, idx) => {
      const prev = this.content[this.content.findIndex((content) => content.id === mediaData.id)];
      if (prev && prev.status === 'syncing') return prev;
      return new Media(this.store, mediaData, false, prev, bomboPrices[idx]);
    });

    this.ads = ads.map((mediaData) => {
      const prev = this.ads[this.ads.findIndex((ad) => ad.id === mediaData.id)];
      if (prev && prev.status === 'syncing') return prev;
      return new Media(this.store, mediaData, true, prev);
    });

    // Calcule ratios
    const totalAdLength = this.ads.reduce((sum, media) => sum + media.length, 0);

    const loopLenAvailableForContent = loopLen - totalAdLength;
    this.loopAdInterval = loopLenAvailableForContent / (this.ads.length || 1);

    this.setPlayerStatus('valid');
    this.nextMedia();

    this.sync(usingLocalCache);
  }

  sync(ignoreDownload = false): void {
    if (this.isSyncing()) {
      console.log('[SYNC] Player is already syncing, skip');
      return;
    }

    const medias = [...this.content, ...this.ads];
    let toSync: Media | undefined = undefined;

    for (const media of medias) {
      if (!toSync && media.status === 'unknown') toSync = media;
    }

    if (toSync) toSync.sync(ignoreDownload);
  }

  getMedia(next = false): Media {
    let shouldBeAnAdMedia = this.loopCounter >= this.loopAdInterval;

    if (shouldBeAnAdMedia && this.ads.length === 0) {
      this.loopCounter -= this.loopAdInterval;
      shouldBeAnAdMedia = false;
    }

    if (shouldBeAnAdMedia) {
      let adIdx = this.adIdx + 1;
      if (adIdx >= this.ads.length) adIdx = 0;
      if (!next) this.adIdx = adIdx;

      const media = this.ads[adIdx];

      console.log(
        `[PLAYER]: ${next ? 'next-media' : 'media'} is an AD ${adIdx + 1} of ${this.ads.length}: ${media.id} - ${
          media.status
        }`,
      );

      return media;
    } else {
      let contentIdx = this.contentIdx + 1;
      if (contentIdx >= this.content.length) contentIdx = 0;
      if (!next) this.contentIdx = contentIdx;

      const media = this.content[contentIdx];

      console.log(
        `[PLAYER]: ${next ? 'next-media' : 'media'} is ${contentIdx + 1} of ${this.content.length}: ${media.id} - ${
          media.status
        }`,
      );

      return media;
    }
  }

  nextMedia(ended = false): void {
    if (!ended && this.media) return;

    const media = this.getMedia();

    if (media.status === 'synced') {
      this.setMedia(media);

      if (media.isAd) this.loopCounter -= this.loopAdInterval;
      else this.loopCounter += media.length;

      return;
    }

    setTimeout(() => this.nextMedia(ended), 50);
  }

  async removeUnusedMedias(): Promise<void> {
    const files = await this.store.fs.listFilesEntries();

    const filesToDelete = files.filter((f) => {
      const contentIdx = this.content.findIndex((c) => f.name === c.id.toString());
      const adsIdx = this.ads.findIndex((c) => f.name === c.id.toString());
      return contentIdx === -1 && adsIdx === -1;
    });

    if (filesToDelete.length === 0) return;
    console.log(`[PLAYER] Deleting ${filesToDelete.length} files: ${filesToDelete.map((f) => f.name).join(', ')}`);

    for (const file of filesToDelete) {
      await this.store.fs.unlink(file.name);
    }
  }
}
