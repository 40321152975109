import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';

import { useStore } from '../../stores/context';
import Media from '../../stores/Media';
import PriceTable, { PriceEntry } from '../cineflix/PriceTable';

interface Props {
  media: Media;
}

interface URI {
  uri: string;
  counter: number;
}

const Video: React.FC<Props> = ({ media }) => {
  const { player } = useStore();
  const [uri, setUri] = useState<URI>();

  useEffect(() => {
    media.getFileUrl().then((uri) => {
      setUri((state) => ({
        uri,
        counter: (state?.counter || 0) + 1,
      }));
    });

    const tm = setTimeout(() => player.nextMedia(true), (media.length + 2) * 1000);
    return () => clearTimeout(tm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [media]);

  function mapBomboPrices(section: string, count: number): PriceEntry[] {
    if (!media.bomboPrices) return [];

    const ret = [];
    for (let i = 0; i < count; i++) {
      const key = `${section}_${i}`;
      const price = media.bomboPrices[key];

      if (!price) {
        ret.push({ name: '', price: 0 });
        continue;
      }

      ret.push({
        name: price.text,
        price: price.price,
      });
    }

    return ret;
  }

  if (!uri) return null;

  return (
    <>
      <ReactPlayer
        key={uri.counter}
        playing
        width="100%"
        height="100%"
        style={{ display: 'flex' }}
        url={uri.uri}
        onError={(): void => window.location.reload()}
        onEnded={(): void => player.nextMedia(true)}
      />

      {media.id === 1255 && (
        <>
          <PriceTable screen={2} posX={114} posY={180} width={316} delay={2200} entries={mapBomboPrices('L', 10)} />
          <PriceTable screen={3} posX={80} posY={140} width={316} delay={2200} entries={mapBomboPrices('RT', 4)} />
          <PriceTable screen={3} posX={80} posY={400} width={316} delay={2200} entries={mapBomboPrices('RB', 4)} />
        </>
      )}

      {media.id === 1256 && (
        <>
          <PriceTable screen={2} posX={80} posY={140} width={316} delay={2200} entries={mapBomboPrices('LT', 4)} />
          <PriceTable screen={2} posX={80} posY={400} width={316} delay={2200} entries={mapBomboPrices('LB', 4)} />
          <PriceTable screen={3} posX={114} posY={180} width={316} delay={2200} entries={mapBomboPrices('R', 10)} />
        </>
      )}
    </>
  );
};

export default observer(Video);
