import { observer } from 'mobx-react-lite';
import React from 'react';

import ErrorMessage from './components/ErrorMessage';
import Image from './components/Image';
import InfoMessage from './components/InfoMessage';
import Splash from './components/Splash';
import Video from './components/Video';
import { useStore } from './stores/context';

const App: React.FC = () => {
  const { player, fs } = useStore();

  if (player.status === 'invalid') return <ErrorMessage centered info="Player inválido" />;
  if (fs.status === 'invalid') return <ErrorMessage centered info="FileStorage não acessível" />;

  const { media } = player;
  if (!media || media.status !== 'synced') return <Splash />;

  if (player.previewMode && !player.previewModeInteraction)
    return (
      <InfoMessage
        centered
        title="Modo visualização"
        info="Você está pré-visualizando as mídias deste player. Esse modo não deve ser utilizado para veiculação em locais públicos."
        onClose={() => player.previewInteraction()}
      />
    );

  if (media.type === 'video') return <Video media={media} />;
  else return <Image media={media} />;
};

export default observer(App);
