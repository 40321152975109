import { action, makeObservable, observable } from 'mobx';

import { BomboPrices, getMedia, MediaData } from '../api';
import Store from './store';

type SyncStatus = 'unknown' | 'syncing' | 'synced' | 'failed';

export default class Media {
  id: number;
  length: number;
  type: 'video' | 'image' = 'video';
  status: SyncStatus = 'unknown';

  constructor(
    private store: Store,
    private mediaData: MediaData,
    public isAd: boolean,
    prev?: Media,
    public bomboPrices?: BomboPrices,
  ) {
    makeObservable(this, { status: observable, setStatus: action });

    const { id, isVideo, length, sha1 } = mediaData;

    this.id = id;
    this.length = length || 15;
    this.type = isVideo ? 'video' : 'image';

    if (prev && prev.status === 'synced' && prev.mediaData.sha1 === sha1) {
      this.setStatus('synced');
    }
  }

  setStatus(status: SyncStatus): void {
    this.status = status;
  }

  getFileUrl(): Promise<string> {
    return this.store.fs.getFileUrl(this.id.toString());
  }

  async sync(ignoreDownload: boolean): Promise<void> {
    const { fs, player } = this.store;
    const { id, orderId, sha1 } = this.mediaData;

    this.setStatus('syncing');

    // Does the file exists ?
    const fileName = id.toString();
    const exists = await fs.fileExists(fileName);

    // Is the file valid ?
    if (exists && (await fs.hashFile(fileName)) === sha1) {
      console.log(`[MEDIA] ok: ${fileName} (${sha1})`);
      this.setStatus('synced');
      return player.sync(ignoreDownload);
    }

    if (ignoreDownload) {
      this.setStatus('failed');
      return player.sync(ignoreDownload);
    }

    console.log(`[MEDIA] downloading ${fileName} (${sha1})`);
    const mediaBuffer = await getMedia(orderId ? `/medias/${orderId}/${id}` : `/contents/${id}`);

    if (!mediaBuffer) {
      this.setStatus('failed');
      return player.sync(ignoreDownload);
    }

    const bufferHash = await fs.hash(mediaBuffer);
    if (bufferHash !== sha1) {
      console.error(`[MEDIA] download failed ${fileName} (${bufferHash})`);
      this.setStatus('failed');
      return player.sync(ignoreDownload);
    }

    console.log(`[MEDIA] download ok ${fileName} (${bufferHash})`);

    if (!(await fs.writeFile(`${id}.downloading`, mediaBuffer))) {
      this.setStatus('failed');
      return player.sync(ignoreDownload);
    }

    if (!(await fs.rename(`${id}.downloading`, fileName))) {
      this.setStatus('failed');
      return player.sync(ignoreDownload);
    }

    this.setStatus('synced');
    player.sync(ignoreDownload);
  }
}
