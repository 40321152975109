import './styles.scss';

import React from 'react';

import icon from './info.svg';

interface Props {
  title?: string;
  info: string;
  close?: string;
  onClose?: () => void;
  centered?: boolean;
}

const InfoMessage: React.FC<Props> = ({ title, info, centered, close, onClose }) => {
  const rootStyle = centered
    ? {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }
    : undefined;

  return (
    <div style={rootStyle}>
      <div className="InfoMessage">
        <div className="info-icon">
          <img src={icon} alt="Info icon" />
        </div>

        <div className="info-text">
          <h1>{title || 'Aviso'}</h1>
          <p>{info}</p>

          <div style={{ textAlign: 'right', paddingTop: 16 }}>
            <button onClick={onClose}>{close || 'OK'}</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoMessage;
