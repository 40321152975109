import './styles.scss';

import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

import { fmtMoney } from '../../../logic/utils';

export type PriceEntry = {
  name: string;
  price: number;
};

interface Props {
  entries: PriceEntry[];
  screen: number;
  posX: number;
  posY: number;
  width: number;
  delay: number;
}

const PriceTable: React.FC<Props> = ({ entries, screen, posX, posY, width, delay }) => {
  const [screenDim, setScreenDim] = useState({ width: 0, height: 0 });
  const [animation, setAnimation] = useState('initial');

  function onResize(): void {
    setScreenDim({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }

  useEffect(() => {
    onResize();

    window.addEventListener('resize', onResize);
    return (): void => window.removeEventListener('resize', onResize);
  }, []);

  useEffect(() => {
    const i = setInterval(() => setAnimation('show'), delay);
    return (): void => clearInterval(i);
  }, [delay]);

  // Get aspect ratios
  let screenWidth = 0;
  let screenHeight = 0;

  const playerAspectRatio = 3200 / 600;
  const screensAspectRatio = screenDim.width / screenDim.height;

  // Height is greater
  if (playerAspectRatio > screensAspectRatio) {
    screenWidth = screenDim.width / 4;
    screenHeight = (screenDim.width / 3200) * 600;
  } else {
    // Width is greater
    screenWidth = 3200 / (600 / screenDim.height) / 4;
    screenHeight = screenDim.height;
  }

  // Calcule scales
  const xScale = screenWidth / 800;
  const yScale = screenHeight / 600;
  const usableScale = xScale > yScale ? yScale : xScale;

  // Calcule positions
  const screenPx = (screen - 1) * screenWidth;
  const pX = screenPx + posX * xScale + (screenDim.width - screenWidth * 4) / 2;
  const pY = posY * yScale + (screenDim.height - screenHeight) / 2;

  // Animations
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        duration: 0,
        staggerChildren: 0.5,
      },
    },
  };

  const item = {
    hidden: {
      opacity: 0,
      transform: 'translateY(-4px)',
      transition: {
        duration: 0.4,
      },
    },
    show: {
      opacity: 1,
      transform: 'translateY(4px)',
      transition: {
        duration: 0.4,
      },
    },
  };

  let idxDiv = 0;

  return (
    <motion.ul
      variants={container}
      initial="hidden"
      animate={animation}
      className="PriceTable"
      style={{
        top: pY,
        left: pX,
        width: width * xScale,
        fontSize: 22 * usableScale,
      }}>
      {entries.map(({ name, price }, idx) => {
        if (!name) {
          idxDiv = idxDiv ? 0 : 1;
          return <li key={idx}>&nbsp;</li>;
        }

        return (
          <motion.li
            variants={item}
            key={idx}
            style={{
              paddingTop: 8 * usableScale,
              paddingBottom: (navigator.userAgent.includes('Mac') ? 2 : 6) * usableScale,
              paddingLeft: 8 * usableScale,
              paddingRight: 8 * usableScale,
              backgroundColor: idx % 2 === idxDiv ? 'rgba(0,0,0,0.2)' : undefined,
            }}>
            {name} <span>R$ {fmtMoney(price)}</span>
          </motion.li>
        );
      })}
    </motion.ul>
  );
};

export default PriceTable;
