import { action, makeObservable, observable } from 'mobx';

import FS from './FS';
import Player from './Player';

export default class Store {
  fs: FS = new FS();
  player: Player = new Player(this);

  needsReaload = false;

  constructor() {
    makeObservable(this, {
      fs: observable,
      player: observable,
      needsReaload: observable,
      scheduleReload: action,
    });
  }

  scheduleReload(): void {
    this.needsReaload = true;
  }
}
