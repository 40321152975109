import { action, makeObservable, observable } from 'mobx';

type Status = 'unknown' | 'invalid' | 'valid';

export default class FS {
  status: Status = 'unknown';

  sha1: Worker;
  fs: FileSystem | undefined;

  constructor() {
    makeObservable(this, {
      status: observable,
      setStatus: action,
    });

    this.sha1 = new Worker('sha1.js');

    const handleError = (err: Error) => {
      console.error(err);
      this.setStatus('invalid');
    };

    const handleSuccess = (fs: FileSystem) => {
      this.fs = fs;
      this.setStatus('valid');
    };

    (window.navigator as any).webkitPersistentStorage.requestQuota(
      1024 * 1024 * 1024 * 4,
      (grantedBytes: number) => {
        const requestFileSystem = window.requestFileSystem || window.webkitRequestFileSystem;
        requestFileSystem(window.PERSISTENT, grantedBytes, handleSuccess, handleError);
      },
      handleError,
    );
  }

  setStatus(status: Status): void {
    this.status = status;
  }

  getFileEntry(name: string, create: boolean | undefined = undefined): Promise<FileEntry | null> {
    return new Promise((resolve) => {
      this.fs?.root.getFile(name, { create }, resolve, () => resolve(null));
    });
  }

  getFile(name: string): Promise<File | null> {
    return new Promise(async (resolve) => {
      const fileEntry = await this.getFileEntry(name);
      fileEntry ? fileEntry.file(resolve, () => resolve(null)) : resolve(null);
    });
  }

  listFilesEntries(): Promise<FileSystemEntry[]> {
    return new Promise((resolve) => {
      if (!this.fs) return resolve([]);
      const reader = this.fs.root.createReader();
      reader.readEntries(resolve, () => resolve([]));
    });
  }

  async readFile(name: string): Promise<ArrayBuffer | null> {
    const file = await this.getFile(name);
    return file ? file.arrayBuffer() : null;
  }

  async writeFile(name: string, data: ArrayBuffer): Promise<boolean> {
    return new Promise(async (resolve) => {
      const fileEntry = await this.getFileEntry(name, true);
      if (!fileEntry) return resolve(false);

      fileEntry.createWriter(
        (writer) => {
          writer.onwriteend = () => resolve(true);
          writer.onerror = () => resolve(false);
          writer.write(new Blob([data]));
        },
        () => resolve(false),
      );
    });
  }

  rename(oldName: string, newName: string): Promise<boolean> {
    return new Promise(async (resolve) => {
      const fileEntry = await this.getFileEntry(oldName);
      if (!this.fs || !fileEntry) return resolve(false);

      fileEntry.moveTo(
        this.fs.root,
        newName,
        () => resolve(true),
        () => resolve(false),
      );
    });
  }

  unlink(name: string): Promise<boolean> {
    return new Promise(async (resolve) => {
      const fileEntry = await this.getFileEntry(name);
      if (!fileEntry) return resolve(false);

      fileEntry.remove(
        () => resolve(true),
        () => resolve(false),
      );
    });
  }

  hash(buffer: ArrayBuffer): Promise<string> {
    return new Promise<string>((resolve) => {
      const onHashed = ({ data }: MessageEvent): void => {
        this.sha1.removeEventListener('message', onHashed);
        resolve(data);
      };

      this.sha1.addEventListener('message', onHashed);
      this.sha1.postMessage(buffer);
    });
  }

  async hashFile(fileName: string): Promise<string> {
    const buffer = await this.readFile(fileName);
    return buffer ? this.hash(buffer) : '';
  }

  async fileExists(name: string): Promise<boolean> {
    const fileEntry = await this.getFileEntry(name);
    return fileEntry ? fileEntry.isFile : false;
  }

  async getFileUrl(name: string): Promise<string> {
    const fileEntry = await this.getFileEntry(name);
    return fileEntry ? fileEntry.toURL() : '';
  }
}
