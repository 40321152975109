import { motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';

import { useStore } from '../../stores/context';
import Media from '../../stores/Media';

interface Props {
  media: Media;
}

interface URIs {
  prev?: string;
  curr: string;
  counter: number;
}

const Image: React.FC<Props> = ({ media }) => {
  const { player } = useStore();

  const [uris, setUris] = useState<URIs>();

  useEffect(() => {
    media.getFileUrl().then((uri) => {
      setUris((state) => ({
        prev: state?.curr,
        curr: uri,
        counter: (state?.counter || 0) + 1,
      }));
    });

    const tm = setTimeout(() => {
      player.nextMedia(true);
    }, 15000);

    return () => clearTimeout(tm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [media]);

  if (!uris) return null;

  const style: React.CSSProperties = {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: window.innerWidth,
    height: window.innerHeight,
  };

  return (
    <>
      <motion.img style={style} src={uris.curr} alt="Current content" />

      {!!uris.prev && (
        <motion.img
          key={uris.counter}
          style={{ ...style }}
          src={uris.prev}
          alt="Previous content"
          animate={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        />
      )}
    </>
  );
};

export default observer(Image);
