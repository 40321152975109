import './styles.scss';

import React from 'react';

import icon from './error.svg';

interface Props {
  title?: string;
  info: string;
  centered?: boolean;
}

const ErrorMessage: React.FC<Props> = ({ title, info, centered }) => {
  const rootStyle = centered
    ? {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }
    : undefined;

  return (
    <div style={rootStyle}>
      <div className="ErrorMessage">
        <div className="error-icon">
          <img src={icon} alt="Error icon" />
        </div>

        <div className="error-text">
          <h1>{title || 'Ops, ocorreu um erro'}</h1>
          <p>{info}</p>
        </div>
      </div>
    </div>
  );
};

export default ErrorMessage;
