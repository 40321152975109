import './styles.scss';

import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

import logo from '../../imgs/logo.svg';

const animations = {
  initial: {
    opacity: 0,
    transform: 'scale(0)',
  },
  show: {
    opacity: 0.4,
    transform: 'scale(0.85)',
    transition: { delay: 1, duration: 1, type: 'spring' },
  },
  big: {
    opacity: 0.7,
    transform: 'scale(0.98)',
    transition: { delay: 0.5, duration: 3, ease: 'easeInOut' },
  },
  normal: {
    opacity: 0.4,
    transform: 'scale(0.85)',
    transition: { duration: 3, ease: 'easeInOut' },
  },
};

const Splash: React.FC = () => {
  const [animation, setAnimation] = useState('initial');

  useEffect(() => {
    const tm = setTimeout(() => setAnimation(animation === 'big' ? 'normal' : 'big'), 3000);
    return (): void => clearTimeout(tm);
  }, [animation]);

  useEffect(() => {
    setAnimation('show');
  }, []);

  return (
    <div className="Splash">
      <motion.img
        variants={animations}
        initial="initial"
        animate={animation}
        src={logo}
        alt="UNID CINEMÍDIA"
        draggable={false}
      />
    </div>
  );
};

export default Splash;
