import './index.scss';
import 'numeral/locales/pt-br';

import { configure } from 'mobx';
import numeral from 'numeral';
import React from 'react';
import { createRoot } from 'react-dom/client';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { store } from './stores/context';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://ded29454cd7f481184e980d313c3d62a@o150551.ingest.sentry.io/5995877',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

numeral.locale('pt-br');

configure({
  enforceActions: 'always',
});

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(<App />);
}

setInterval(() => serviceWorkerRegistration.update(), 60000);

serviceWorkerRegistration.register({
  onUpdate: () => store.scheduleReload(),
});
